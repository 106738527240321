body {
    transition: background-color 0.3s, color 0.3s;
}

#modeToggle {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

#modeToggle svg {
    fill: currentColor;
}

.light-mode {
    background-color: #fff;
    color: #000;
}

.dark-mode {
    background-color: #363537;
    color: #FAFAFA;
}

.mode-title {
    color: #000000;
}

.light-mode .mode-title {
    color: #000000; /* Slightly darker gray for better visibility in light mode */
}

.dark-mode .mode-title {
    color: #ffffff; /* Lighter gray for better visibility in dark mode */
}

.mode-sec-bg {
    background-color: #f3f4f6;
}

.light-mode .mode-sec-bg {
    background-color: #f3f4f6; /* Slightly darker gray for better visibility in light mode */
}

.dark-mode .mode-sec-bg {
    background-color: #3c3c3d; /* Lighter gray for better visibility in dark mode */
}

.mode-text-500 {
    color: #6b7280; /* Default gray color */
}

.light-mode .mode-text-500 {
    color: #6b7280; /* Slightly darker gray for better visibility in light mode */
}

.dark-mode .mode-text-500 {
    color: #dbdbdb; /* Lighter gray for better visibility in dark mode */
}

.mode-text-400 {
    color: #9ca3af; /* Default gray color */
}

.light-mode .mode-text-400 {
    color: #9ca3af; /* Slightly darker gray for better visibility in light mode */
}

.dark-mode .mode-text-400 {
    color: #cbcbcb; /* Lighter gray for better visibility in dark mode */
}

.mode-btn-bg {
    background-color: #000000; /* Default gray color */
}

.light-mode .mode-btn-bg {
    background-color: #000000; /* Slightly darker gray for better visibility in light mode */
}

.dark-mode .mode-btn-bg {
    background-color: #FFFFFF; /* Lighter gray for better visibility in dark mode */
}

.mode-btn-text {
    color: #FFFFFF; /* Default gray color */
}

.light-mode .mode-btn-text {
    color: #FFFFFF; /* Slightly darker gray for better visibility in light mode */
}

.dark-mode .mode-btn-text {
    color: #000000; /* Lighter gray for better visibility in dark mode */
}

.featured_item {
    background-color: #f1f1f1; /* Default gray color */
}

.light-mode .featured_item {
    background-color: #f1f1f1; /* Slightly darker gray for better visibility in light mode */
}

.dark-mode .featured_item {
    background-color: #3c3c3d; /* Lighter gray for better visibility in dark mode */
}

.item_stats {
    color: #6b7280; /* Default gray color */
}

.light-mode .item_stats {
    color: #6b7280; /* Slightly darker gray for better visibility in light mode */
}

.dark-mode .item_stats {
    color: #dbdbdb; /* Lighter gray for better visibility in dark mode */
}

.item_stats span {
    color: #6b7280; /* Default gray color */
}

.light-mode .item_stats span {
    color: #6b7280; /* Slightly darker gray for better visibility in light mode */
}

.dark-mode .item_stats span {
    color: #dbdbdb; /* Lighter gray for better visibility in dark mode */
}